import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, LinearProgress, LinearProgressProps, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SdkUpload from '../../../SdkUpload/SdkUpload';
import { db, storage } from '../../../../Firebase';
import {sdksByPlatform} from "../../../../constants";

interface SdkVersionFormDialogProps {
  open: boolean
  setOpen: (status: boolean) => void
  isCreate: boolean,
  platform: string,
  editRow: any
  setEditRow: any
  selectedSdk: string
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
      <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" {...props} />
          </Box>
          <Box minWidth={10}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
				  props.value,
              )}%`}
              </Typography>
          </Box>
      </Box>
  );
}

const SdkVersionFormDialog = ({
  open, setOpen, isCreate, platform, editRow, setEditRow,
                                selectedSdk,
}: SdkVersionFormDialogProps) => {
  const classes = useStyles();

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sdkFile, setSDKFile] = useState<File | null>(null);
  const [releaseNotes, setReleaseNotes] = useState<string | null>(null);
  const [releaseNotesId, setReleaseNotesId] = useState('');

  const handleChangeSDKFile = (event: any) => {
    const file = event.target.files[0];
    setSDKFile(file);
  };
  const handleChangeDescription = (value: any) => {
    if (editRow) setReleaseNotesId(editRow.id);
    setEditRow(null);
    setReleaseNotes(value);
  };

  const updateSdkVersion = (value: any) => {
      const {base} = sdksByPlatform[platform][selectedSdk];

      const sdkRef = db.collection(`${base}/${platform}/versions`);
      if (releaseNotesId !== null) {
          sdkRef.doc(releaseNotesId).update(
              {
                  releaseNotes: value,
              },
          ).then(() => {
              setOpen(false);
          }).catch(() => setOpen(false));
      }
  };

  const createSdkVersion = () => {
      const {base} = sdksByPlatform[platform][selectedSdk];
      uploadSDK('', base);
  };


    const uploadSDK = (filePrefix: string, collectionPrefix: string) => {
        if (sdkFile) {
            setLoading(true);
            const uploadTask = storage.ref(`${filePrefix}${platform}/${sdkFile.name}`).put(sdkFile);
            uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setUploadProgress(Math.trunc(progress));
            }, (error) => {
                // Handle unsuccessful uploads
                console.log('Error on uploading ', error);
            }, () => {
                const sdkRef = db.collection(`${collectionPrefix}/${platform}/versions`);
                sdkRef.doc().set(
                    {
                        file: `/${filePrefix}${platform}/${sdkFile.name}`,
                        releaseNotes,
                        date: +new Date(),
                    },
                );
                setLoading(false);
                setSDKFile(null);
                setUploadProgress(0);
                // setPlatform('');
                setReleaseNotes(null);
                setOpen(false);
            });
        }
    }

  return (<Dialog
      className={classes.createDialog}
      open={open}
      keepMounted
      onClose={() => { setEditRow(null); setOpen(false); }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
  >
      <DialogTitle id="alert-dialog-slide-title">{`Add SDK ${platform} version`}</DialogTitle>
      <DialogContent>
          {isCreate ? <SdkUpload
              handleChangeSDKFile={handleChangeSDKFile}
              releaseNotes={releaseNotes}
              isCreate={isCreate}
              handleChangeDescription={handleChangeDescription}
              platform={platform}
          />
			  : <SdkUpload
    editRow={editRow}
    handleChangeSDKFile={handleChangeSDKFile}
    isCreate={isCreate}
    releaseNotes={editRow ? editRow.releaseNotes : releaseNotes}
    handleChangeDescription={handleChangeDescription}
    platform={platform}
			  />}

          {sdkFile ? (<div className={classes.uploadContainer}>
              <div className={classes.fileName}>File: {sdkFile?.name}</div>
              {loading ? <LinearProgressWithLabel value={uploadProgress} /> : null}
                      </div>) : null}

      </DialogContent>
      <DialogActions>
          {isCreate ? <Button disabled={loading} className={classes.uploadButton} variant="contained" color="primary" onClick={createSdkVersion}>
              {!loading && 'Upload'}
              {loading && <CircularProgress size={25} color="secondary" />}
                      </Button>
			  : <Button disabled={loading} className={classes.uploadButton} variant="contained" color="primary" onClick={() => updateSdkVersion(releaseNotes)}>
    {!loading && 'Update'}
    {loading && <CircularProgress size={25} color="secondary" />}
       </Button>}
      </DialogActions>
          </Dialog>);
};

const useStyles = makeStyles((theme) => ({
  createDialog: {
    width: '100%',
  },
  contentDialog: {
    width: '50%',
  },
  uploadContainer: {
    width: '100%',
  },
  fileName: {
    marginBottom: '10px',
    textAlign: 'center',
  },
  uploadButton: {
    alignSelf: 'center',
  },
}));

export default SdkVersionFormDialog;
