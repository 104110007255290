import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Formik} from 'formik';
import {Button, Paper, TextField, CircularProgress} from '@material-ui/core';
import {firebaseApp} from '../../Firebase'
import LoginSchema from './ValidationSchema'
import './Login.css'
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiTextField-root': {
            margin: theme.spacing(3),
            width: 300,
        },
    },
    title: {
        fontSize: '24px',
        marginBottom: '10px',
        textAlign: 'center',
    },
    paper: {
        padding: '40px'
    },
    button: {
        width: '200px',
        marginTop: '50px'
    },
    error: {
        color: 'red'
    }
}));

interface MyFormValues {
    email: string;
    password: string;
}

const Login: React.FC<{}> = () => {
    const classes = useStyles();
    let history = useHistory();
    const [authError, setAuthError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const initialValues: MyFormValues = {
        email: '',
        password: ''
    };

    return (
        <div className='login-container'>
            <Paper className={classes.paper} elevation={3}>
                <div className={classes.title}>Sign in to your account</div>
                    <Formik
                        initialValues={initialValues}
                        validateOnBlur={true}
                        onSubmit={({email, password}: MyFormValues) => {
                            setLoading(true)
                            firebaseApp.auth().signInWithEmailAndPassword(email, password).then((res) => {
                                history.push('/')
                            }).catch((err) => {
                                setAuthError(true)
                            }).finally(()=> {
                                setLoading(false)
                            })
                        }}
                        validationSchema={LoginSchema}
                        render={({values, handleChange, errors, touched, handleSubmit, handleBlur}) =>
                            (<form className={classes.root} onSubmit={handleSubmit}>
                                {authError ? <div className={classes.error}>Invalid email or password</div> : null}
                                <TextField
                                    name='email'
                                    type='email'
                                    onChange={(e) => {
                                        setAuthError(false);
                                     handleChange(e)}}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    label='Email:'
                                    error={touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                />
                                <TextField
                                    name='password'
                                    type='password'
                                    onChange={(e) => {
                                        setAuthError(false);
                                        handleChange(e)}}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    label='Password:'
                                    error={touched.password && !!errors.password}
                                    helperText={touched.password && errors.password}
                                />
                                <Button
                                    type='submit'
                                    className={classes.button}
                                    variant='contained'
                                    color='primary'
                                    disabled={authError || loading || !!(!values.email || errors.email) || !!(!values.password || errors.password)}>
                                    {!loading && 'SIGN IN'}
                                    {loading && <CircularProgress size={25} color={"secondary"} />}
                                </Button>
                            </form>)}
                    />
            </Paper>
        </div>


    )
}

export default Login
