import {createStore, combineReducers, compose} from 'redux';
import {useSelector, TypedUseSelectorHook} from 'react-redux'
import {devToolsEnhancer} from 'redux-devtools-extension';
import {userReducer} from './Reducers/User/reducers'

const rootReducer = combineReducers({
    user: userReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
    const enhancers = process.env.NODE_ENV !== 'production' ? devToolsEnhancer({}) : compose;
    return createStore(
        rootReducer,
        enhancers
    );
}

export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;
