export const sdksMap: any = {
    'wt-web': {
        name: 'Web WT SDK',
        route: 'web_sdk/wt/releases',
    },
    'synch-web': {
        name: 'Web Synch SDK',
        route: 'web_sdk/synch/releases',
    },
    'chat-web': {
        name: 'Web Chat SDK',
        route: 'web_sdk/chat/releases',
    }
};

export const sdksByPlatform: any = {
    android: {
        android_kotlin: {
            name: 'Android WT SDK (Kotlin)',
            base: 'wt_kotlin',
        },
        sync: {
            name: 'Android Sync SDK',
            base: 'sync_sdk',
        },
        android: {
            name: '[Legacy] Android WT SDK',
            base: 'sdk_new',
        },
        community: {
            name: 'Android Community SDK',
            base: 'community_sdk',
        },
    },
    ios: {
        'swift-web': {
            name: 'iOS WT SDK (Swift)',
            base: 'wt_swift',
        },
        sync: {
            name: 'iOS Sync SDK',
            base: 'sync_sdk',
        },
        ios: {
            name: '[Legacy] iOS WT SDK',
            base: 'sdk_new',
        },
        community: {
            name: 'iOS Community SDK',
            base: 'community_sdk',
        },
    },
    tvos: {
        sdk_new: {
            name: 'tvOS WT SDK',
            base: 'sdk_new',
        },
    },
    unity: {
        sdk_new: {
            name: 'Unity WT SDK',
            base: 'sdk_new',
        },
    },
};
