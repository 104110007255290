import {SET_USER_DATA, SET_USER_KEYS, UserActionTypes, UserState} from './types';

const initialState: UserState = {
    isAuthenticated: false,
    admin: false,
    email: '',
    apiKey: '',
    apiSecret: '',
    casURL: ''
};

export function userReducer(state=initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case SET_USER_DATA:
            return {...state, ...action.payload};
        case SET_USER_KEYS:
            return {...state, ...action.payload};
        default:
            return state
    }
}
