import app from 'firebase/app';
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
const {
    REACT_APP_API_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_DATABASE_URL,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_ID,
    REACT_APP_MESSAGE_SENDER_ID,
    REACT_APP_APP_ID,
    REACT_APP_MEASUREMENT_ID} = process.env;

const firebaseConfig = {
    apiKey: REACT_APP_API_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    databaseURL: REACT_APP_DATABASE_URL,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_ID,
    messagingSenderId: REACT_APP_MESSAGE_SENDER_ID,
    appId: REACT_APP_APP_ID,
    measurementId: REACT_APP_MEASUREMENT_ID
};

export const firebaseApp = app.initializeApp(firebaseConfig);

export const db = app.firestore();

export const storageRef = app.storage().ref();

export const storage = app.storage();

export const taskState = app.storage.TaskState;
