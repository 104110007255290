import * as Yup from 'yup';

const EMAIL_MIN_LENGTH: number = 6;
const EMAIL_MAX_LENGTH: number = 70;
const PASSWORD_MIN_LENGTH: number = 6;
const PASSWORD_MAX_LENGTH: number = 70;


export default Yup.object().shape({
    email: Yup.string()
        .required('Email required')
        .email('Email is not valid')
        .min(EMAIL_MIN_LENGTH, `Email too short - Min length is ${EMAIL_MIN_LENGTH} characters`)
        .max(EMAIL_MAX_LENGTH, `Email too long - Max length is ${EMAIL_MAX_LENGTH} characters`),

    password: Yup.string().trim()
        .required('Password is Required')
        .min(PASSWORD_MIN_LENGTH, 'Password too short')
        .max(PASSWORD_MAX_LENGTH, `Password too long - Max length is ${PASSWORD_MAX_LENGTH} characters`),
});