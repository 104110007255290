import React from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import {db} from '../../../../Firebase';
import {sdksByPlatform} from "../../../../constants";

interface SdkVersionDialogProps {
    open: boolean
    setOpen: (status: boolean) => void
    deleteRow: any
    platform: string,
    selectedSdk: string,
}

const SdkVersionDeleteDialog = ({
                                    open, setOpen, deleteRow, platform,
                                    selectedSdk,
                                }: SdkVersionDialogProps) => {
    const cancelDeleteVersion = () => {
        setOpen(false);
    };
    const acceptDeleteVersion = () => {
        let {base} = sdksByPlatform[platform][selectedSdk];
        const sdkRef = db.collection(`${base}/${platform}/versions`);
        sdkRef.doc(deleteRow.id).delete().then(() => {
            console.log('Success delete ');
            setOpen(false);
        });
        setOpen(false);
    };

    return (<Dialog
        open={open}
        keepMounted
        onClose={cancelDeleteVersion}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle id="alert-dialog-slide-title">Delete SDK version</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete this version ?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={cancelDeleteVersion} color="primary">
                No
            </Button>
            <Button onClick={acceptDeleteVersion} color="primary">
                Yes
            </Button>
        </DialogActions>
    </Dialog>);
};
export default SdkVersionDeleteDialog;
