export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_KEYS = 'SET_USER_KEYS';

export interface SetUserData {
    isAuthenticated: boolean,
    email: string,
    apiKey?: string,
    apiSecret?: string,
    casURL?: string,
    admin?: boolean
}

interface SetUserDataAction {
    type: typeof SET_USER_DATA,
    payload: SetUserData
}

export interface SetUserKeys {
    apiKey: string,
    apiSecret: string,
    casURL: string
}

interface SetUserKeysAction {
    type: typeof SET_USER_KEYS,
    payload: SetUserKeys
}

export interface UserState {
    isAuthenticated: boolean,
    admin: boolean
    email: string,
    apiKey: string,
    apiSecret: string,
    casURL: string
}

export type UserActionTypes = SetUserDataAction | SetUserKeysAction;
