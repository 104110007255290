import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { firebaseApp, db } from '../../Firebase';
import Header from '../Header/Header';
import Login from '../Login/Login';
import SdkDownload from '../SdkDownload/SdkDownload';
import { setUserData } from '../../Store/Reducers/User/actions';
import { useTypedSelector } from '../../Store';
import './App.css';
import VersionsList from '../VersionsList/VersionsList';
import WebSdkReleases from "../Web/WebSdkReleases";

interface UserDataInterface {
  apiKey?: string,
  apiSecret?: string,
  casURL?: string,
  admin?: boolean
}

const App: React.SFC<{}> = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { isAuthenticated, admin } = useTypedSelector((state) => state.user);

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      setLoading(false);

      if (user) {
        db.collection('customers').doc(user.uid).get().then((res) => {
          const userData = res.data() as UserDataInterface;

          dispatch(setUserData({ isAuthenticated: true, email: user.email || '', ...userData }));
        });
      } else {
        dispatch(setUserData({
          isAuthenticated: false, email: '', admin: false, apiSecret: '', apiKey: '', casURL: '',
        }));
      }
    });
  }, [dispatch]);

  if (loading) {
    return <div className="app-loading">
        <CircularProgress />
    </div>;
  }

  return (
      <Router>
          <div className="app-container">
              <Header />
              <Route exact={true} path="/" render={() => (isAuthenticated ? <SdkDownload /> : <Login />)} />
              <Route path="/android" render={() => (isAuthenticated ? <VersionsList admin={admin} platform="android" /> : <Login />)} />
              <Route path="/ios" render={() => (isAuthenticated ? <VersionsList admin={admin} platform="ios" /> : <Login />)} />
              <Route path="/tvos" render={() => (isAuthenticated ? <VersionsList admin={admin} platform="tvos" /> : <Login />)} />
              <Route path="/unity" render={() => (isAuthenticated ? <VersionsList admin={admin} platform="unity" /> : <Login />)} />
              <Route path="/web" render={() => (isAuthenticated ? <WebSdkReleases admin={admin} platform="ios"  /> : <Login />)} />
          </div>
      </Router>
  );
};

export default App;
