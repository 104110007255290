import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  TextField,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectPlatform: {
    maxWidth: 500,
  },
  uploadButtonContainer: {
    marginTop: '20px',
  },
  button: {
    width: '250px',
    color: '#ffffff',
    marginLeft: '20px',
    '@media (max-width: 780px)': {
      marginBottom: '20px',
      marginLeft: '0',
      height: '50px',
    },
    textTransform: 'none',
  },
  android: {
    backgroundColor: '#A6C736',
    '&:hover': {
      backgroundColor: '#A6C736',
    },
  },
  apple: {
    backgroundColor: '#ffffff',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
  },
  uploadContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  uploadButton: {
    maxWidth: '150px',
    alignSelf: 'center',
  },
  fileName: {
    marginBottom: '10px',
  },
}));

interface SdkUploadProps {
  platform: string;
  handleChangeSDKFile?: (event:any) => void
  handleChangeDescription: (event:any) => void
  releaseNotes: string | null
  editRow?: any
  isCreate?: any
}

const SdkUpload: React.FC<SdkUploadProps> = ({
  platform, handleChangeDescription, handleChangeSDKFile, releaseNotes, isCreate,
}) => {
  const classes = useStyles();

  return (
      <div className={classes.root}>
          <FormControl className={classes.formControl}>
              {platform ? (<div className={classes.uploadButtonContainer}>
                  <div style={{ height: '60px' }}>
                      <label htmlFor="upload-sdk">
                          <input
                              style={{ display: 'none' }}
                              type="file"
                              id="upload-sdk"
                              name="upload-sdk"
                              onChange={handleChangeSDKFile}
                          />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <TextField
                                  multiline
                                  rows={8}
                                  style={{ marginBottom: 10 }}
                                  label="Release notes"
                                  defaultValue={releaseNotes}
                                  value={releaseNotes}
                                  onChange={(event) => handleChangeDescription(event.target.value)}
                              />
                              { isCreate ? <Button
                                  className={`${classes.button} ${platform === 'android' ? classes.android : classes.apple}`}
                                  startIcon={<PublishIcon />}
                                  variant="contained"
                                  component="span"
                              >
                                  Click here to select file
                              </Button> : null}
                          </div>
                      </label>

                  </div>
                           </div>) : null}

          </FormControl>
      </div>
  );
};

export default SdkUpload;
