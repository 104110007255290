import {SET_USER_DATA, SET_USER_KEYS, UserActionTypes, SetUserData, SetUserKeys} from './types';

export function setUserData(user: SetUserData): UserActionTypes {
    return {
        type: SET_USER_DATA,
        payload: user
    }
}

export function setUserKeys(keys: SetUserKeys) {
    return {
        type: SET_USER_KEYS,
        payload: keys
    }
}
