import React from 'react';
import { useTypedSelector } from '../../Store';
import { firebaseApp } from '../../Firebase';
import './Header.css';
import { useHistory } from 'react-router';

const Header: React.FC = () => {
  const history = useHistory();
  const isAuthenticated = useTypedSelector((state) => state.user.isAuthenticated);

  const doLogOut = () => {
    firebaseApp.auth().signOut();
  };
  const toHome = () => {
    history.push('/');
  };

  return (<header className="sceenic-header">
      <div className="header-container">
          <div className="left-side" style={{ cursor: 'pointer' }} onClick={() => toHome()}>
              <img className="logo" alt="Sceenic" src="/sceenic_logo.png" />
          </div>
          <div className="right-side">
              {isAuthenticated && <div className="logout" onClick={doLogOut}>Log Out</div>}
          </div>
      </div>
  </header>);
};

export default Header;
