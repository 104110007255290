import React from 'react';
import { Button, TextField } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import WebIcon from '@material-ui/icons/Language'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, withRouter } from 'react-router';
import { useTypedSelector } from '../../Store';
import {ReactComponent as ReactLogo1} from './unity.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '120px',
    '& .MuiTextField-root': {
      margin: theme.spacing(3),
      width: '100%',
      '@media (max-width: 780px)': {
        margin: theme.spacing(3, 0),
      },
    },
  },
  inputContainer: {
    margin: '50px auto 0',
    width: '100%',
    maxWidth: '70%',
    minWidth: '700px',
    '@media (max-width: 780px)': {
      minWidth: 'unset',
      maxWidth: '90%',
      marginTop: '25px',
    },
  },
  inputBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',

  },
  icon: {
    marginBottom: '24px',
    cursor: 'pointer',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    margin: '50px auto 0',
    width: '100%',
    maxWidth: '70%',
    minWidth: '700px',
    justifyContent: 'center',
    '@media (max-width: 780px)': {
      flexDirection: 'column',
      minWidth: 'unset',
      maxWidth: '90%',
    },
  },
  button: {
    width: '150px',
    color: '#ffffff',
    marginLeft: '20px',
    '@media (max-width: 780px)': {
      marginBottom: '20px',
      marginLeft: '0',
      height: '50px',
    },
    textTransform: 'none',
  },
  android: {
    backgroundColor: '#A6C736',
    '&:hover': {
      backgroundColor: '#A6C736',
    },
  },
  apple: {
    backgroundColor: '#ffffff',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
  },

  tvos: {
    backgroundColor: '#F3F3F3',
    color: '#303030',
    '&:hover': {
      backgroundColor: '#F3F3F3',
      color: '#303030',
    },
  },
}));

interface UserDataInterface {
  isAuthenticated: boolean,
  email: string,
  apiKey: string,
  apiSecret: string,
  casURL: string,
  npmToken?: string,
  [key: string]: any
}

const SdkDownload = () => {
  const history = useHistory();
  const classes = useStyles();
  const userData: UserDataInterface = useTypedSelector((state) => state.user);

  const copyToClipboard = (field?: string) => {
    const stringToCopy = field || '';
    navigator.clipboard.writeText(stringToCopy);
  };

  const navigateTo = (path: string) => {
    history.push(`/${path}`);
  };

  const syncToken = `${userData.casURL}/sync/token/`;
  const wtToken = `${userData.casURL}/stream/token/v2/`;

  return (
      <div className={classes.root}>
          <div className={classes.buttons}>
              <Button
                  className={`${classes.button} ${classes.apple}`}
                  onClick={() => navigateTo('ios')}
                  variant="contained"
                  startIcon={<AppleIcon />}
              >iOS SDK
              </Button>
              <Button
                  className={`${classes.button} ${classes.tvos}`}
                  onClick={() => navigateTo('tvos')}
                  variant="contained"
                  startIcon={<AppleIcon />}
              >tvOS SDK
              </Button>
              <Button
                  className={`${classes.button} ${classes.android}`}
                  onClick={() => navigateTo('android')}
                  variant="contained"
                  startIcon={<AndroidIcon />}
              >Android SDK
              </Button>
              <Button
                  className={`${classes.button} ${classes.apple}`}
                  onClick={() => navigateTo('unity')}
                  variant="contained"
                  startIcon={<ReactLogo1 width={20} height={20} />}
              >Unity SDK
              </Button>
              <Button
                  className={`${classes.button} ${classes.tvos}`}
                  onClick={() => navigateTo('web')}
                  variant="contained"
                  startIcon={<WebIcon />}
              >Releases web
              </Button>
          </div>
          <div className={classes.inputContainer}>
              <div className={classes.inputBlock}>
                  <TextField
                      disabled
                      label="API_KEY"
                      value={userData.apiKey}
                  />
                  <FileCopyOutlinedIcon className={classes.icon} onClick={() => copyToClipboard(userData.apiKey)} />
              </div>
              <div className={classes.inputBlock}>
                  <TextField
                      disabled
                      label="API_SECRET"
                      value={userData.apiSecret}
                  />
                  <FileCopyOutlinedIcon className={classes.icon} onClick={() => copyToClipboard(userData.apiSecret)} />
              </div>

              <div className={classes.inputBlock}>
                  <TextField
                      disabled
                      label="CAS_URL"
                      value={userData.casURL}
                  />
                  <FileCopyOutlinedIcon className={classes.icon} onClick={() => copyToClipboard(userData.casURL)} />
              </div>

              <div className={classes.inputBlock}>
                  <TextField
                      disabled
                      label="WT SDK Token URL"
                      value={wtToken}
                  />
                  <FileCopyOutlinedIcon className={classes.icon} onClick={() => copyToClipboard(wtToken)} />
              </div>

              <div className={classes.inputBlock}>
                  <TextField
                      disabled
                      label="Sync SDK Token URL"
                      value={syncToken}
                  />
                  <FileCopyOutlinedIcon className={classes.icon} onClick={() => copyToClipboard(syncToken)} />
              </div>

              {userData.npmToken ? (<div className={classes.inputBlock}>
                  <TextField
                      disabled
                      label="NPM_TOKEN"
                      value={userData.npmToken}
                  />
                  <FileCopyOutlinedIcon className={classes.icon} onClick={() => copyToClipboard(userData.npmToken)} />
                                    </div>) : null}

          </div>
      </div>
  );
};

export default withRouter(SdkDownload);
