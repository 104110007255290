import React, {useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, LinearProgress, LinearProgressProps, Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {db} from "../../../Firebase";
import CreateRelease from "../forms/CreateRelease";
import EditRelease from "../forms/EditRelease";
import {sdksMap} from "../../../constants";


interface SdkVersionFormDialogProps {
    open: boolean
    setOpen: (status: boolean) => void
    isCreate: boolean,
    editRow: any
    setEditRow: any
    selectedSdk: string
}


const CreateDialogWebsdk = ({
                                open, setOpen, isCreate, editRow, setEditRow,
                                selectedSdk,
                            }: SdkVersionFormDialogProps) => {
    const classes = useStyles();

    const [releaseNotes, setReleaseNotes] = useState<string | null>(null);
    const [releaseNotesId, setReleaseNotesId] = useState('');
    const [sdkVersion, setSdkVersion] = useState<string | null>(null);

    const handleChangeDescription = (value: any) => {
        if (editRow) setReleaseNotesId(editRow.id);
        setEditRow(null)
        setReleaseNotes(value);
    };

    const handleChangeVersion = (value: any) => {
        if (editRow) setReleaseNotesId(editRow.id);
        setEditRow(null)
        setSdkVersion(value);
    }

    const updateWebSdkRelease = (release_notes: any, version: any) => {
        const path = sdksMap[selectedSdk].route;
        const sdkRef = db.collection(path);
        const data = {
            updated_at: +new Date(),
        }
        if (release_notes) {
            //@ts-ignore
            data.release_notes = release_notes
        }
        if (version) {
            //@ts-ignore
            data.version = version
        }
        if (releaseNotesId !== null) {
            sdkRef.doc(releaseNotesId).update(
                data
            ).then(() => {
                setOpen(false);
            }).catch(() => {
                setOpen(false)
            });

        }
    };

    const createWebSdkRelease = () => {
        const path = sdksMap[selectedSdk].route;
        db.collection(path).add({
            created_at: +new Date(),
            updated_at: +new Date(),
            release_notes: releaseNotes,
            version: sdkVersion
        }).then(() => {
            setOpen(false)
        }).catch(() => {
            setOpen(false)
        });
    }


    return (<Dialog
        className={classes.createDialog}
        open={open}
        keepMounted
        onClose={() => {
            setEditRow(null);
            setOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle id="alert-dialog-slide-title"> Create Web SDK release</DialogTitle>
        <DialogContent className={classes.contentDialogStyle}>
            {isCreate ? <CreateRelease
                    releaseNotes={releaseNotes}
                    version={sdkVersion}
                    handleChangeDescription={handleChangeDescription}
                    handleChangeVersion={handleChangeVersion}
                />
                : <EditRelease
                    editRow={editRow}
                    releaseNotes={editRow ? editRow.release_notes : releaseNotes}
                    version={editRow ? editRow.version : sdkVersion}
                    handleChangeDescription={handleChangeDescription}
                    handleChangeVersion={handleChangeVersion}
                />}

        </DialogContent>
        <DialogActions>
            {isCreate ? <Button className={classes.uploadButton} variant="contained" color="primary"
                                onClick={createWebSdkRelease}>
                    Create
                </Button>
                : <Button className={classes.uploadButton} variant="contained" color="primary"
                          onClick={() => updateWebSdkRelease(releaseNotes, sdkVersion)}>
                    Update
                </Button>}
        </DialogActions>
    </Dialog>);
};

const useStyles = makeStyles((theme) => ({
    createDialog: {
        width: '100%',
    },
    contentDialog: {
        width: '50%',
    },
    uploadContainer: {
        width: '100%',
    },
    contentDialogStyle: {
        height: 300,
    },
    fileName: {
        marginBottom: '10px',
        textAlign: 'center',
    },
    uploadButton: {
        alignSelf: 'center',
    },
}));

export default CreateDialogWebsdk;
