import React from 'react'
import {FormControl, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
const EditRelease = ({

                         releaseNotes,
                            version,
                         handleChangeDescription,
                         handleChangeVersion
                     }: any) => {
    const classes = useStyles();
    return <div>
        <FormControl className={classes.formControl}>
           <div className={classes.uploadButtonContainer}>
                <div style={{ height: '60px' }}>
                    <label htmlFor="upload-sdk">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                style={{ marginBottom: 5 }}
                                label="Version"
                                defaultValue={version}
                                value={version}
                                onChange={(event) => handleChangeVersion(event.target.value)}
                            />
                            <TextField
                                multiline
                                rows={7}
                                style={{ marginBottom: 5 }}
                                label="Release notes"
                                defaultValue={releaseNotes}
                                value={releaseNotes}
                                onChange={(event) => handleChangeDescription(event.target.value)}
                            />

                        </div>
                    </label>

                </div>
            </div>

        </FormControl>
    </div>
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
        height: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 400,
    },
    selectPlatform: {
        maxWidth: 500,
    },
    uploadButtonContainer: {
        marginTop: '20px',
    },
    button: {
        width: '250px',
        color: '#ffffff',
        marginLeft: '20px',
        '@media (max-width: 780px)': {
            marginBottom: '20px',
            marginLeft: '0',
            height: '50px',
        },
        textTransform: 'none',
    },
    android: {
        backgroundColor: '#A6C736',
        '&:hover': {
            backgroundColor: '#A6C736',
        },
    },
    apple: {
        backgroundColor: '#ffffff',
        color: '#000000',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: '#000000',
        },
    },
    uploadContainer: {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    uploadButton: {
        maxWidth: '150px',
        alignSelf: 'center',
    },
    fileName: {
        marginBottom: '10px',
    },
}));
export default EditRelease