import React, {forwardRef, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Button,
    Typography,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Check';
import Back from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Clear';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import {db, storageRef} from '../../Firebase';
import {separateVersion} from '../../helpers';
import SdkVersionDeleteDialog from './components/SdkVersionDeleteDialog/SdkVersionDeleteDialog';
import SdkVersionFormDialog from './components/SdkVersionFormDialog/SdkVersionFormDialog';
import {useHistory} from "react-router";
import {sdksByPlatform} from "../../constants";

interface VersionsListProps {
    platform: string
    admin: boolean
}

interface RowInterface {
    file: string,
    date: number,
    releaseNotes: string,
    id?: string
}

interface PlatformInterface {
    android: any,
    ios: any,
    tvos: any,
    unity: any,
    id: any

    [key: string]: string
}

const menuTitles: {
    android: string
    ios: string
    tvos: string
} = {
    android: 'Kotlin',
    ios: 'Swift',
    tvos: ''
}


const VersionsList: React.FC<VersionsListProps> = ({platform, admin}) => {
    const classes = useStyles();
    const [sdkVersions, setSdkVersions] = useState<RowInterface[]>([]);
    const history = useHistory();
    const [remove, setRemove] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [isCreate, setIsCreate] = useState<boolean>(false);
    const [deleteRow, setDeleteRow] = useState<RowInterface | null>(null);
    const [editRow, setEditRow] = useState<RowInterface | null>(null);
    const defaultSelected = Object.keys(sdksByPlatform[platform])[0];
    const [selectedSdk, setSelected] = useState(defaultSelected);

    useEffect(() => {
        getSdkVersionsList();
    }, []);


    useEffect(() => {
        getSdkVersionsList()
    }, [selectedSdk])

    const getSdkVersionsList = () => {
        let {base} = sdksByPlatform[platform][selectedSdk];
        db.collection(`${base}/${platform}/versions`).orderBy('date', 'desc').limit(100).get()
            .then((documents) => {
                const result: any[] = [];
                documents.forEach((doc) => {
                    result.push({id: doc.id, ...doc.data()} as PlatformInterface);
                });
                setSdkVersions(result);
            })
            .catch((error) => console.log('error ', error));
    };
    const handleBack = () => {
        history.goBack();
    }

    const handleDownloadSdk = (path: string) => {
        storageRef.child(path).getDownloadURL().then((url) => {
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    const openRemove = (row: RowInterface) => {
        setDeleteRow(row);
        setRemove(true);
    };

    const closeRemove = (status: boolean) => {
        setRemove(status);
        setDeleteRow(null);
        getSdkVersionsList();
    };

    const openCreate = () => {
        setEditRow(null);
        setIsCreate(true);
        setCreate(true);
    };
    const openEdit = (rowData: RowInterface) => {
        setEditRow(rowData);
        setCreate(true);
    };
    const handleClose = (status: boolean) => {
        setCreate(status);
        setIsCreate(false);
        setEditRow(null);
        getSdkVersionsList();
    };

    const columns = [{title: 'Version', field: 'file', render: (row: RowInterface) => (separateVersion(row.file))},
        {
            title: 'Release Notes',
            field: 'releaseNotes',
            cellStyle: {
                width: '45%',
                'white-space': 'pre-wrap',
                fontSize: 14,
            },
        },
        {
            title: 'Date',
            field: 'date',
            render: (row: RowInterface) => (`${new Date(row.date).toDateString()} ${new Date(row.date).toTimeString().slice(0, 5)}`)
        }];

    const adminActions = [
        {
            icon: () => <CreateIcon/>,
            tooltip: 'Edit',
            onClick: (event: any, rowData: any) => openEdit(rowData),
        },
        {
            icon: () => <DeleteIcon/>,
            tooltip: 'Delete',
            onClick: (event: any, row: any) => openRemove(row),
        },
        {
            icon: () => <AddBoxIcon/>,
            tooltip: 'Add Sdk',
            isFreeAction: true,
            onClick: (event: any) => openCreate(),
        },
    ];
    const userActions = [
        {
            icon: () => <DownloadIcon/>,
            tooltip: 'Download',
            onClick: (event: any, rowData: any) => {
                handleDownloadSdk(rowData.file.trim())
            },
        },
    ];

    const platformButtons = Object.keys(sdksByPlatform[platform]).map((key) => {
        const {name} = sdksByPlatform[platform][key];
        return (
            <Button
                key={key}
                variant="outlined"
                className={classes.selectButton}
                color={selectedSdk === key ? 'primary' : 'default'}
                onClick={() => setSelected(key)}
            >
                <Typography className={classes.title}>{name}</Typography>
            </Button>
        );
    });


    return (<div className={classes.androidVersionsContainer}>
        <div className={classes.backContainer}>
            <Button className={classes.backButton} onClick={() => handleBack()}> <Back/></Button>
        </div>
        <div className={classes.sdkSelector}>
            {platformButtons}
        </div>
        <MaterialTable
            style={{width: '80%'}}
            options={{
                sorting: false,
                search: false,
                paging: true,
                pageSize: 10,
                paginationType: "stepped",
                showFirstLastPageButtons: false,
                actionsColumnIndex: 3,
                draggable: false
            }}
            title=""
            columns={columns}
            data={sdkVersions}
            icons={{
                Add: forwardRef((props, ref) => (<AddBoxIcon {...props} />)),
                Check: forwardRef((props, ref) => (<SaveIcon {...props} />)),
                Clear: forwardRef((props, ref) => (<CancelIcon {...props} />)),
            }}
            actions={admin ? adminActions : userActions}
        />
        {remove ? <SdkVersionDeleteDialog
            selectedSdk={selectedSdk}
            open={true} setOpen={closeRemove} deleteRow={deleteRow} platform={platform}/> : null}
        {create ? <SdkVersionFormDialog
            selectedSdk={selectedSdk}
            platform={platform}
            isCreate={isCreate}
            setEditRow={setEditRow}
            open={true}
            editRow={editRow}
            setOpen={(event) => handleClose(event)}
        /> : null}
    </div>);
};

const useStyles = makeStyles((theme) => ({
    androidVersionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10%',
        width: '100%',
    },
    backButton: {
        // justifySelf: 'flex-start'
        marginLeft: 15,

    },
    backContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: 10,
    },
    tableContainer: {
        width: '80%',
    },
    selectButton: {
        margin: 5,
    },
    sdkSelector: {
        display: 'flex',
        flexDirection: 'row',
        margin: 5,
    },
    title: {
        textTransform: 'none',
    },
    android: {
        backgroundColor: '#A6C736',
        '&:hover': {
            backgroundColor: '#A6C736',
        },
    },
    button: {
        width: '150px',
        color: '#ffffff',
        marginLeft: '20px',
        '@media (max-width: 780px)': {
            marginBottom: '20px',
            marginLeft: '0',
            height: '50px',
        },
        textTransform: 'none',
    },
}));
export default VersionsList;
